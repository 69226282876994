import React, { useState, useContext } from 'react'
import { LocationContext } from '../contexts/locationContext'

import DashboardStats from '../components/DashboardStats'
import DashboardFranchises from '../components/DashboardFranchises'
import Spinner from '../components/Spinner'
import TopRightInfo from '../components/TopRightInfo'
import Modal from 'react-modal'
import DashboardPopupTop from '../components/DashboardPopupTop'
import DashboardPopupMiddle from '../components/DashboardPopupMiddle'
import DashboardPopupBottom from '../components/DashboardPopupBottom'
import GingrApiKeyModal from '../components/modals/GingrApiKeyModal'
import { CentralbarkContext } from '../contexts/centralbarkContext'


const Dashboard = () => {
  const { stores, setStartLoadingStores } = React.useContext(CentralbarkContext);
  const { locations, isLoading, setStartLoadingLocations } = useContext(LocationContext)
  const [reviewModalIsOpen, setReviewModalIsOpen] = useState(false);
  const [gingrApiKeyModalIsOpen, setGingrApiKeyModalIsOpen] = useState(false);
  const [selectedFranchise, setSelectedFranchise] = useState(0)

  React.useEffect(() => {
    setStartLoadingLocations(true);
    setStartLoadingStores(true);
  }, [])

  const modalStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '80vw',
      height: 'auto',
      maxHeight: '100vh',
      position: 'relative',
      overflow: 'scroll',
    },
  };

  function openModal(id) {
    switch (id) {
      case "franchise":
        setReviewModalIsOpen(true)
        break;
      case "gingr":
        setGingrApiKeyModalIsOpen(true)
        break;
      default:
        return;
    }
  }

  function afterOpenModal() { }

  function closeModal(id) {
    switch (id) {
      case "franchise":
        setReviewModalIsOpen(false)
        break;
      case "gingr":
        setGingrApiKeyModalIsOpen(false)
        break;
      default:
        return;
    }
  }

  const displayPopup = (id) => {
    const location = locations.find(el => el.bId === id)
    const store = stores.find(el => el.bId === id)
    setSelectedFranchise({ ...location, ...store })
  }

  if (isLoading) return <div className='dashboard-wrapper'>
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <h1 className='dashboard-heading' onClick={openModal}>Dashboard</h1>
      <TopRightInfo />
    </div>
    <div>
      <Spinner />
    </div>
  </div>

  return (
    <div className='dashboard-wrapper'>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <h1 className='dashboard-heading' onClick={openModal}>Dashboard</h1>
        <TopRightInfo />
      </div>
      <DashboardStats />
      <DashboardFranchises
        setSelectedFranchise={setSelectedFranchise}
        displayPopup={displayPopup}
        openModal={openModal}
      />

      <Modal
        isOpen={reviewModalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={() => closeModal("franchise")}
        style={modalStyles}
        contentLabel="Franchise Details">
        <DashboardPopupTop franchise={selectedFranchise} />
        <DashboardPopupMiddle franchise={selectedFranchise} />
        <DashboardPopupBottom franchise={selectedFranchise} />
      </Modal>

      <Modal
        isOpen={gingrApiKeyModalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={() => closeModal("gingr")}
        style={modalStyles}
        contentLabel="Franchise Details">
        <GingrApiKeyModal store={selectedFranchise} />
      </Modal>
    </div>
  )
}

export default Dashboard
