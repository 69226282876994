import React from 'react'
import { UserContext } from '../contexts/userContext'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import Logo from '../components/Logo'
import Spinner from '../components/Spinner'

const ResetPassword = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const email = searchParams.get("email");
  const token = searchParams.get("token");

  const [isLoading, setIsLoading] = React.useState(true);
  const [password, setPassword] = React.useState('')
  const [confirmPassword, setConfirmPassword] = React.useState('')

  const { error, resetPassword, checkToken } = React.useContext(UserContext)

  const tokenVerification = async () => checkToken({ token, email });

  const handleTokenSuccess = () => setIsLoading(false);
  const handleTokenBadRequest = () => window.location = 'https://brightreviews.app/reset-password/';
  const handleTokenExpired = () => navigate(`/link-expired?email=${email}`);


  React.useEffect(() => {
    tokenVerification()
      .then(() => setIsLoading(false))
      .catch(error => {
        switch (error.response.status) {
          case 200: 
            return handleTokenSuccess();
          case 400:
            return handleTokenBadRequest();
          case 401:
            return handleTokenExpired();
          default:
            console.log(error.response.data);
            break;
        }
      });
  }, []);

  const resetPasswordHandler = async e => {
    e.preventDefault()
    if (password !== confirmPassword) {
      toast.error('Passwords do not match')
      return
    }
    resetPassword(email, password, token)
  }

  if (isLoading) return <Spinner />

  return (
    <div className='login-wrapper'>
      <Logo to="/login" />
      {error && <h4 style={{ color: '#ff0000', fontSize: '2rem', textAlign: 'center', padding: '1rem 0' }}>{error}</h4>}
      <form onSubmit={resetPasswordHandler} className='login-form'>
        <div className="login-form-group">
          <label htmlFor="password">Your New Password</label>
          <input value={password} onChange={e => setPassword(e.target.value)} type="password" className="login-email" required />
        </div>
        <div className="login-form-group">
          <label htmlFor="confirmPassword">Confirm Your New Password</label>
          <input value={confirmPassword} onChange={e => setConfirmPassword(e.target.value)} type="password" className="login-email" required />
        </div>
        <input type='submit' className='login-button' value='Reset your password' />
      </form>
    </div>
  )
}

export default ResetPassword