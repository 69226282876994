import React from "react"
import { Route, Navigate, Outlet } from "react-router-dom"

const Authmiddleware = () => {
  const user = localStorage.getItem("br_cb_user")
  return (
    user ? <Outlet /> : <Navigate to="/login" />
  );
}

export default Authmiddleware;