import { useContext } from 'react'
import DashboardFranchiseTableEntry from './DashboardFranchiseTableEntry'
import { LocationContext } from '../contexts/locationContext'

const DashboardActiveFranchises = ({ displayPopup, openModal, setSelectedFranchise }) => {

  const { locations } = useContext(LocationContext)

  return (
    <>
      <div className="dashboard-franchises-wrapper">
        <h3 className='dashboard-franchises-heading'>Active Franchises</h3>

        <div className='dashboard-franchises-table-wrapper' style={{ overflowX: 'auto' }}>
          <table className='dashboard-franchises-table'>
            <thead className='dashboard-franchises-thead'>
              <tr>
                <th className='dashboard-franchises-th'>LOCATION</th>
                <th className='dashboard-franchises-th'>OWNER NAME</th>
                <th className='dashboard-franchises-th'>CITY/STATE</th>
                <th className='dashboard-franchises-th'>REQUESTS SENT</th>
                <th className='dashboard-franchises-th'>THUMBS UP</th>
                <th className='dashboard-franchises-th'>THUMBS DOWN</th>
                <th className='dashboard-franchises-th'>{"Negative Comments".toUpperCase()}</th>
              </tr>
            </thead>
            <tbody className='dashboard-franchises-tbody'>
              {locations && locations.map((location, index) => (
                <DashboardFranchiseTableEntry
                  key={index}
                  id={location.bId}
                  location={location}
                  setSelectedFranchise={setSelectedFranchise}
                  openModal={openModal}
                  displayPopup={displayPopup} />
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  )
}

export default DashboardActiveFranchises