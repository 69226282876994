import React from "react";
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import UsStates from "../data/usStates";

const UsStatesDropdown = ({ onChange }) => {
    const [value, setValue] = React.useState(UsStates.usStates[0]);
    const handleChange = (event) => {
        setValue(event.value);
        onChange({
            target: {
                name: "state",
                value: UsStates.usStatesAbbreviated[UsStates.usStates.indexOf(event.value)] 
            }
        })
    };
    return <Dropdown className="br-dropdown" options={UsStates.usStates} onChange={handleChange} value={value} placeholder="Select a state" />;

}

export default UsStatesDropdown