import React from 'react'
import Modal from 'react-modal'

export default function CancelSubscriptionModal({
    isOpen,
    closeModal
}) {
    const modalStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '50vw',
            minHeight: '50vh',
            position: 'relative'
        },
    };

    return (
        <div className='cancel-sub-modal'>
            <Modal
                isOpen={isOpen}
                onAfterOpen={() => { }}
                onRequestClose={closeModal}
                style={modalStyles}
                contentLabel="Change Credit Card"
            >
                <h3 style={{ color: "#662E89", textAlign: 'center', fontSize: '2rem', marginBottom: '2rem' }}>Are you sure you want to cancel? You will NOT be able to use this location anymore!</h3>
                <span style={{ fontSize: '3rem', cursor: 'pointer', position: 'absolute', top: '0.5rem', right: '2rem' }} onClick={closeModal}>&times;</span>

            </Modal>
        </div>
    )
}
