import { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { LocationContext } from '../contexts/locationContext'

const DashboardStats = () => {

  const { numberOfLocations, totalRequests, totalThumbsUp, totalThumbsDown, totalNegative } = useContext(LocationContext)
  const navigate = useNavigate()

  const handleAddNewFranchise = () => {
    navigate('/add-new-franchise')
  }

  return (
    <div className="dashboard-stats-card">
        <div className="dashboard-stats-content">
          <div className='dashboard-stats-content-label'>
            Requests Sent: <span className='dashboard-stats-content-value'>{totalRequests}</span>
          </div>
          <div className='dashboard-stats-content-label'>
            Thumbs Up: <span className='dashboard-stats-content-value'>{totalThumbsUp}</span>
          </div>
          <div className='dashboard-stats-content-label'>
            Thumbs Down: <span className='dashboard-stats-content-value'>{totalThumbsDown}</span>
          </div>
          <div className='dashboard-stats-content-label'>
            Negative Comments: <span className='dashboard-stats-content-value'>{totalNegative}</span>
          </div>
          <div style={{ fontSize: '1.4rem' }} className='dashboard-stats-content-label'>
            Locations: <span className='dashboard-stats-content-value'>{numberOfLocations}</span>
          </div>
        </div>
        <div className="dashboard-stats-location-and-button">
          <button className='dashboard-stats-add-franchise-button' onClick={handleAddNewFranchise} >Add new franchise</button>
        </div>
      </div>
  )
}

export default DashboardStats