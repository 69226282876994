import { useState } from 'react'
import { ReactComponent as Logo } from '../assets/icons/bright-reviews-logo.svg'
import { ReactComponent as DashboardIcon } from '../assets/icons/home.svg'
import { ReactComponent as BillingIcon } from '../assets/icons/activity.svg'
import { ReactComponent as SidebarCollapseIcon } from '../assets/icons/sidebar-collapse.svg'
import { Link, useLocation } from 'react-router-dom'

const Sidebar = () => {
  const [sidebarOpen, setSidebarOpen] = useState(true)

  const location = useLocation()

  return (
    <div className={`sidebar-outer-wrapper ${!sidebarOpen && 'sidebar-closed'}`}>
      <div className={`sidebar ${!sidebarOpen && 'sidebar-closed'}`}>
        <div className="sidebar-container">
          <Logo className='sidebar-logo' />
          <div>
            <Link className={`sidebar-item ${location.pathname === '/' && 'sidebar-item-active'}`} to='/'>
              <DashboardIcon className='sidebar-item-icon' /> <span className='sidebar-item-text'>Dashboard</span>
            </Link>
          </div>
          <div>
            <Link className={`sidebar-item ${location.pathname === '/billing' && 'sidebar-item-active'}`} to='/billing'>
              <BillingIcon className='sidebar-item-icon' /> <span className='sidebar-item-text'>Billing</span>
            </Link>
          </div>
          {/* <div>
            <Link className={`sidebar-item ${location.pathname === '/settings' && 'sidebar-item-active'}`} to='/settings'>
              <BillingIcon className='sidebar-item-icon' /> <span className='sidebar-item-text'>Settings</span>
            </Link>
          </div> */}
        </div>
        <SidebarCollapseIcon className='sidebar-collapse-icon' onClick={() => setSidebarOpen(prevState => setSidebarOpen(!prevState))} />
      </div>
    </div>
  )
}

export default Sidebar