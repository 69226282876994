import React from 'react'
import downloadIcon from '../../assets/icons/arrow-down.svg'

export default function BillingHistory({ invoices }) {
    return (
        <div className="billing-history">
            <div className="billing-subscriptions-heading">
                <h6>Billing History</h6>
            </div>
            <div className='billing-history-table-wrapper'>
                <table className='subscriptions-table billing-history-table'>
                    <thead>
                        <tr>
                            <th>INVOICE NUMBER</th>
                            <th>DATE</th>
                            <th>AMOUNT</th>
                            <th>STATUS</th>
                            <th>DOWNLOAD</th>
                        </tr>
                    </thead>
                    <tbody>
                        {invoices.map(invoice => (
                            <tr key={invoice.number}>
                                <td>{invoice.number}</td>
                                <td>{invoice.date}</td>
                                <td>${invoice.amount}</td>
                                <td><span className='table-primary'>{invoice.status}</span></td>
                                <td><a href={invoice.pdf}><img src={downloadIcon} alt="" className='download-icon' /></a></td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    )
}
