import spinner from '../assets/icons/spinner.svg'

import React from 'react'

const Spinner = () => {
  return (
    <img src={spinner} alt="Loading..." className='spinner' />
  )
}

export default Spinner