import { useState, useContext } from 'react'
import { Link } from 'react-router-dom'
import { ReactComponent as Logo } from '../assets/icons/bright-reviews-logo.svg'
import { UserContext } from '../contexts/userContext'

const Login = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const { login, error } = useContext(UserContext)

  const loginHandler = e => {
    e.preventDefault()
    login(email, password)
  }

  return (
    <div className='login-wrapper'>
      <Logo className='login-logo' />
      {error && <h4 style={{ color: '#ff0000', fontSize: '2rem', textAlign: 'center', padding: '1rem 0' }}>{error}</h4>}
      <form onSubmit={loginHandler} className='login-form'>
        <div className="login-form-group">
          <label htmlFor="email">Email</label>
          <input value={email} onChange={e => setEmail(e.target.value)} type="email" className="login-email" required />
        </div>
        <div className="login-form-group">
          <label htmlFor="password">Password</label>
          <input value={password} onChange={e => setPassword(e.target.value)} type="password" className="login-password" required />
        </div>
        <input type='submit' className='login-button' value='Login' />
      </form>
      <Link to="/forgot-password" className="login-forgot-password">Forgout Your Password?</Link>
    </div>
  )
}
 
export default Login