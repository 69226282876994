import React from 'react'
import MobileTop from './MobileTop';
import Sidebar from "./Sidebar";
import MobileNavbar from './MobileNavbar'

export default function Layout({ children }) {
    return (
        <div className='route-wrapper'>
            <MobileTop />
            <Sidebar />
            {children}
            <MobileNavbar />
        </div>
    )
}
