import React from 'react'

const DashboardPopupTop = ({ franchise }) => {
  return (
    <div className="dashboard-popup-top">
      <h2 className='dashboard-popup-top-business'>{franchise.businessName}</h2>
      <div className="dashboard-popup-top-data">
        <p className='dashboard-popup-top-location'>{franchise.city}, {franchise.state}</p>
        <p className='dashboard-popup-top-owner'>{franchise.ownerName}</p>
      </div>
    </div>
  )
}

export default DashboardPopupTop