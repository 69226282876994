import React from "react"
import { useNavigate } from "react-router-dom"

export const AuthContext = React.createContext({})

export const AuthProvider = ({ children }) => {

  const [token, setToken] = React.useState(localStorage.getItem('br_cb_token') ? localStorage.getItem('br_cb_token') : null)
  const navigate = useNavigate();

  // React.useEffect(() => {
  //   if (token) return;
  //   return navigate("/login");
  // }, [token]);

  const value = {
    token, setToken
  }

  return <AuthContext.Provider value={value}>
    {children}
  </AuthContext.Provider>
}
