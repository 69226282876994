import React from 'react'

const FormField = ({ label, type, placeholder, name, value, onChange, required = true, touched, error }) => {
  return (
    <div className='form-group'>
      <label htmlFor="">{label}</label>
      <input className={error ? 'add-franchise-input_error' : ''} type={type} name={name} value={value} onChange={onChange} placeholder={placeholder ? placeholder : null} required={required} />
      {touched && error && (
        <span className='text-red-400'>{error}</span>
      )}
    </div>
  )
}

FormField.defaultProps = {
  type: 'text'
}

export default FormField