import React from "react"
import { useNavigate } from "react-router-dom"
import CentralbarkApi from "../api/CentralbarkApi"
import { AuthContext } from "./authContext"

export const CentralbarkContext = React.createContext({})

export const CentralbarkProvider = ({ children }) => {
    const navigate = useNavigate();
    const [stores, setStores] = React.useState(null)
    const [startLoadingStores, setStartLoadingStores] = React.useState(false);
    const { token } = React.useContext(AuthContext)

    const getStores = async () => {
        const stores = await CentralbarkApi.getStores(token).catch(reason => {
            if (reason.response.status === 401) {
                navigate("/login")
            }
        });
        setStores(stores)
    }

    const patchStore = async (store) => await CentralbarkApi.patchStore({
        store: {
            value: store.key,
        },
        token,
        bId: store.bId
    });

    const getState = async () => {
        await getStores();
    }

    React.useEffect(() => {
        if (startLoadingStores)
            getState()
    }, [startLoadingStores])

    const value = {
        stores, getStores, patchStore, setStartLoadingStores
    }

    return <CentralbarkContext.Provider value={value}>{children}</CentralbarkContext.Provider>
}