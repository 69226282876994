import { ReactComponent as HomeIcon } from '../assets/icons/home.svg'
import { ReactComponent as BillingIcon } from '../assets/icons/activity.svg'
import { Link, useLocation } from 'react-router-dom'

const MobileNavbar = () => {
  const location = useLocation()

  return (
    <div className='mobile-navbar'>
      <Link to='/' className={`mobile-sidebar-item ${location.pathname === '/' && 'mobile-sidebar-item-active'}`}>
        <HomeIcon />
        <p>Dashboard</p>
      </Link>
      <Link to='/billing' className={`mobile-sidebar-item ${location.pathname === '/billing' && 'mobile-sidebar-item-active'}`}>
        <BillingIcon />
        <p>Billing</p>
      </Link>
      {/* <Link to='/settings' className={`mobile-sidebar-item ${location.pathname === '/settings' && 'mobile-sidebar-item-active'}`}>
        <BillingIcon />
        <p>Settings</p>
      </Link> */}
    </div>
  )
}

export default MobileNavbar