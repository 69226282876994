import React from 'react'
import { useFormik } from 'formik';
import * as yup from 'yup'

const CBFormSchema = yup.object().shape({
    storeKey: yup.string()
        .min(2, 'Too Short!')
        .max(50, 'Too Long!')
        .required('Required'),
})

export default function CBForm({ store, onSubmit }) {
    const formik = useFormik({
        initialValues: {
            storeName: store.name,
            storeKey: store.key
        },
        validationSchema: CBFormSchema,
        onSubmit: values => {
            onSubmit(values)
        },
        enableReinitialize: true
    })
    return (
        <form onSubmit={formik.handleSubmit}>
            <div className="form-group">
                <label htmlFor="storeKey">Key</label>
                <input id="storeKey" name="storeKey" placeholder="Key" onChange={formik.handleChange} value={formik.values.storeKey} />
            </div>

            <button type="submit">Change</button>
        </form>
    )
}
