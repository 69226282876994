
import React from "react"
import { ReactComponent as BillingIcon } from '../assets/icons/activity.svg'
import Tippy from '@tippyjs/react';

const DashboardFranchiseTableEntry = ({ location, displayPopup, id, openModal }) => {
  return (
    <tr className='dashboard-franchises-tbody-tr'>
      <td className='dashboard-franchises-td' style={{
        textAlign: 'start'
      }}>
        <div onClick={() => {
          displayPopup(id)
          openModal("franchise")
        }
        } style={{ color: '#662E89', textAlign: 'start', textDecoration: 'underline', display: 'inline-block', cursor: 'pointer' }}>
          {location.businessName}
        </div>
        <br />
        <div style={{
          display: 'flex',
          justifyContent: "space-between"
        }}>
          <p style={{
            color: '#ABABB7'
          }}>
            {location.businessId}
          </p>
          <Tippy content="Gingr API Keys">
            <BillingIcon onClick={() => {
              displayPopup(id)
              openModal('gingr')
            }} style={{
              fill: '#ababb7',
              cursor: 'pointer'
            }} />
          </Tippy>
        </div>

      </td>
      <td className='dashboard-franchises-td' style={{
        textAlign: 'start'
      }}>{location.ownerName}</td>
      <td className='dashboard-franchises-td' style={{
        textAlign: 'start'
      }}>{location.city}, {location.state}</td>
      <td className='dashboard-franchises-td' style={{ color: '#B84E96', }}>{location.requests}</td>
      <td className='dashboard-franchises-td' style={{ color: '#662E89', }}>{location.thumbsUp}</td>
      <td className='dashboard-franchises-td' style={{ color: '#AC2037', }}>{location.thumbsDown}</td>
      <td className='dashboard-franchises-td' style={{ color: '#E9CA33', }}>{location.negative}</td>
    </tr>
  )
}

export default DashboardFranchiseTableEntry