import React from 'react'
import starFilled from '../assets/icons/star-full.svg'
import Carousel from 'react-elastic-carousel'
import DashboardPopupReviewSlide from './DashboardPopupReviewSlide'
import { LocationContext } from '../contexts/locationContext'

const DashboardPopupMiddle = ({ franchise }) => {
  const {
    getFacebookReviews,
    getGoogleReviews
  } = React.useContext(LocationContext)
  const [totalReviews, setTotalReviews] = React.useState([]);
  const [mergedReviews, setMergedReviews] = React.useState([]);

  React.useEffect(() => {
    (async () => {
      if (mergedReviews.length) return;
      const fbReviews = await getFacebookReviews(franchise.bId);
      const googleReviews = await getGoogleReviews(franchise.bId);
      setTotalReviews({
        ...fbReviews,
        ...googleReviews
      });
      if (fbReviews?.fbReviews?.reviews) setMergedReviews([...mergedReviews, ...fbReviews.fbReviews.reviews])
      if (googleReviews?.googleReviews?.reviews) setMergedReviews([...mergedReviews, ...googleReviews.googleReviews.reviews])
    })();
  }, [franchise.bId, getFacebookReviews, getGoogleReviews, mergedReviews])

  const getStars = () => {
    if (!totalReviews.googleReviews?.averageRating) return <></>;
    const averageRating = Math.floor(totalReviews.googleReviews?.averageRating);
    return [...Array(averageRating)].map((e, i) => <img src={starFilled} alt="" className='dashboard-popup-star' key={i} />)
  }

  return (
    <div className="dashboard-popup-middle">
      <div className="dashboard-popup-middle-left">
        <div className="dashboard-popup-stats">
          <div className="dashboard-popup-stat">
            <div className="dashboard-popup-stat-label">
              Signed up date:
            </div>
            <div className="dashboard-popup-stat-value">
              {franchise.dateAdded}
            </div>
          </div>
          <div className="dashboard-popup-stat">
            <div className="dashboard-popup-stat-label">
              Requests sent:
            </div>
            <div className="dashboard-popup-stat-value">
              {franchise.requests}
            </div>
          </div>
          <div className="dashboard-popup-stat">
            <div className="dashboard-popup-stat-label">
              Thumbs down ratings:
            </div>
            <div className="dashboard-popup-stat-value">
              {franchise.thumbsDown}
            </div>
          </div>
          <div className="dashboard-popup-stat">
            <div className="dashboard-popup-stat-label">
              Thumbs up ratings:
            </div>
            <div className="dashboard-popup-stat-value">
              {franchise.thumbsUp}
            </div>
          </div>
          <div className="dashboard-popup-stat">
            <div className="dashboard-popup-stat-label">
              Reviews received:
            </div>
            <div className="dashboard-popup-stat-value">
              ?
            </div>
          </div>
          <div className="dashboard-popup-stat">
            <div className="dashboard-popup-stat-label">
              Negative comments:
            </div>
            <div className="dashboard-popup-stat-value dashboard-popup-stat-value-negative">
              {franchise.negative}
            </div>
          </div>
        </div>
      </div>
      <div className="dashboard-popup-middle-right">
        <div className="reviews-top">
          <div className="reviews-top-text">
            <h3>Recent Reviews</h3>
            <p>Total <span className='dashboard-popup-number-reviews'>{franchise.reviewsReceived}</span> reviews</p>
          </div>
          <div className="dashboard-popup-stars">
            {totalReviews && getStars()}
          </div>
        </div>
        <div className="dashboard-popup-reviews-slider">
          {
            totalReviews?.googleReviews?.reviews.length > 0 && (
              <Carousel>
                {mergedReviews.length && mergedReviews?.map((review, index) => (
                  <React.Fragment key={index}>
                    <DashboardPopupReviewSlide review={review} key={index} />
                    {totalReviews.googleReviews?.reviews.length > 0
                      ? <p className='dashboard-popup-review-number'>Review <span className='dashboard-popup-review-active'>{index + 1}</span> of {mergedReviews.length}</p>
                      : <p className='dashboard-popup-review-number' style={{ fontSize: '14px !important' }}><strong>No reviews</strong></p>}
                  </React.Fragment>
                ))}
              </Carousel>
            )
          }

          {totalReviews.googleReviews?.reviews.length > 0
            ? <p className='dashboard-popup-review-number'>Review <span className='dashboard-popup-review-active'>1</span> of {mergedReviews.length}</p>
            : <p className='dashboard-popup-review-number'><strong>No reviews</strong></p>}
        </div>
      </div>
    </div>
  )
}

export default DashboardPopupMiddle

