import React from 'react'
import { useNavigate } from 'react-router-dom'
import { ReactComponent as BrLogo } from '../assets/icons/bright-reviews-logo.svg'

export default function Logo({ to }) {
    const navigate = useNavigate()

    return (
        <BrLogo className='login-logo' onClick={() => navigate(to)} style={{
            cursor: 'pointer'
        }} />
    )
}
