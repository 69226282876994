import axios from 'axios'

const getStores = async (token) => {
    return await axios.get('https://centralbarkbe.newbr.app/api/table/stores', {
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
    .then(res => res.data.data);
}

const patchStore = async ({ store, token, bId }) => {
    return await axios.patch(
        `https://centralbarkbe.newbr.app/api/table/stores/${bId}`,
        store,
        {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }
    )
    .catch(e => console.log(e.message));
}

const CentralbarkApi = {
    getStores,
    patchStore
};

export default CentralbarkApi;
