import { useContext, useEffect } from 'react'
import FormField from "../components/FormField"
import { LocationContext } from '../contexts/locationContext'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import TopRightInfo from '../components/TopRightInfo'
import UsStatesDropdown from '../components/UsStatesDropdown'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import usStates from '../data/usStates'
import UsStates from '../data/usStates'

const AddNewFranchise = () => {

  const { message, setMessage, numberOfLocations, addNewLocation, insertTableEntries } = useContext(LocationContext)

  const formik = useFormik({
    initialValues: {
      businessId: '',
      businessName: '',
      businessEmail: '',
      ownerName: '',
      ownerEmail: '',
      address1: '',
      address2: '',
      city: '',
      zipcode: '',
      state: usStates[0],
      phoneNumber: '',
      gingrURL: '',
      termsAndConditions: false
    },
    validationSchema: Yup.object({
      businessId: Yup.string()
        .required("Field is required")
        .matches(/^[a-zA-Z0-9]+$/, 'Cannot contain special characters or spaces'),
      businessName: Yup.string().required("Business Name is required"),
      businessEmail: Yup.string().required("Business Email is required").email("Invalid email"),
      ownerName: Yup.string().required("Owner Name is required"),
      ownerEmail: Yup.string().required("Owner Email is required").email("Invalid email"),
      address1: Yup.string().required("Address is required"),
      address2: Yup.string(),
      city: Yup.string().required("City is required"),
      zipcode: Yup.string().required("Zipcode is required"),
      state: Yup.string().oneOf([UsStates.usStatesAbbreviated], 'Invalid State'),
      phoneNumber: Yup.string().required("Phone Number is required"),
      gingrURL: Yup.string().required("Gingr URL is required"),
      termsAndConditions: Yup.bool().required().oneOf([true], 'You need to accept the terms and conditions'),
    }),
    validateOnChange: true,
    onSubmit: values => {
      addNewLocation(values)
      navigate("/")
    }
  })

  const [params] = useSearchParams()

  const navigate = useNavigate()

  useEffect(() => {
    const sessionId = params.get('session_id')
    if (sessionId) {
      insertTableEntries(sessionId)
    }
  }, [insertTableEntries, params])

  useEffect(() => {
    if (message && numberOfLocations) {
      toast.success(message)
      setMessage('')
    }
  }, [message, numberOfLocations, setMessage])

  return (
    <div className="add-new-franchise-wrapper">
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <h1 className='dashboard-heading'>Add New Franchise</h1>
        <TopRightInfo />
      </div>
      <div className="add-new-franchise-container">
        <h6 className="add-new-franchise-title">Location Info</h6>
        <form onSubmit={formik.handleSubmit} noValidate>
          <div className="form-group business-id">
            <label htmlFor="">BusinessID</label>
            <input className={formik.errors.businessId ? 'add-franchise-input_error' : ''} type="text" name='businessId' value={formik.values.businessId} onChange={formik.handleChange} onC placeholder="Vanity URL - e.g. CentralBarkCoralSprings" />
            <p className="business-id-info">Your URL will be https://brightreviews.app/{formik.values.businessId} <br />
              The vanity URL that you choose during registration cannot be changed.
            </p>
            {formik.touched.businessId && formik.errors.businessId && (
              <span className='text-red-400'>{formik.errors.businessId}</span>
            )}
          </div>
          <div className="form-fields-wrapper">
            <FormField label='Business Name' name='businessName' value={formik.values.businessName} onChange={formik.handleChange} touched={formik.touched.businessName} error={formik.errors.businessName} />
            <FormField label='Business Email' type='email' name='businessEmail' value={formik.values.businessEmail} onChange={formik.handleChange} touched={formik.touched.businessEmail} error={formik.errors.businessEmail} />
            <FormField label='Owner Name' name='ownerName' value={formik.values.ownerName} onChange={formik.handleChange} touched={formik.touched.ownerName} error={formik.errors.ownerName} />
            <FormField label='Owner Email' type='email' name='ownerEmail' value={formik.values.ownerEmail} onChange={formik.handleChange} touched={formik.touched.ownerName} error={formik.errors.ownerName} />
            <FormField label='Phone Number' name='phoneNumber' value={formik.values.phoneNumber} onChange={formik.handleChange} touched={formik.touched.phoneNumber} error={formik.errors.phoneNumber} />
            <FormField label='Gingr URL' name='gingrURL' value={formik.values.gingrURL} onChange={formik.handleChange} touched={formik.touched.gingrURL} error={formik.errors.gingrURL} />
            <FormField label='Address Line 1' name='address1' value={formik.values.address1} onChange={formik.handleChange} touched={formik.touched.address1} error={formik.errors.address1} />
            <FormField label='Address Line 2' name='address2' value={formik.values.address2} onChange={formik.handleChange} touched={formik.touched.address2} error={formik.errors.address2} />
            <FormField label='City' name='city' value={formik.values.city} onChange={formik.handleChange} touched={formik.touched.city} error={formik.errors.city} />
            <FormField label='Zipcode' name='zipcode' value={formik.values.zipcode} onChange={formik.handleChange} touched={formik.touched.zipcode} error={formik.errors.zipcode} />
            <div className='form-group'>
              <label htmlFor="">State</label>
              <UsStatesDropdown onChange={formik.handleChange} />
              {formik.touched.state && formik.errors.state && (
                <span className='text-red-400'>{formik.errors.state}</span>
              )}
            </div>
            <div></div>
            <div className="form-group terms-and-conditions">
              <div>
              <input
                type="checkbox"
                name='termsAndConditions'
                checked={formik.values.termsAndConditions}
                value={formik.values.termsAndConditions}
                onChange={formik.handleChange}
              />
                <label htmlFor="">{ ` ` }I agree to the <span>Terms and Conditions</span></label> <br />
                </div>
              {formik.touched.termsAndConditions && formik.errors.termsAndConditions && (
                <span className='text-red-400'>{formik.errors.termsAndConditions}</span>
              )}
            </div>
          </div>
          <input disabled={!formik.isValid} className='add-button' type="submit" value="Add to Subscription" />
        </form>
      </div>
    </div>
  )
}

export default AddNewFranchise