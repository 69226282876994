import React from 'react'
import axios from 'axios'
import { toast } from 'react-toastify'
import { AuthContext } from './authContext'
import { useNavigate } from 'react-router-dom'

export const LocationContext = React.createContext({
  location: {}
})

export const LocationProvider = ({ children }) => {
  const [location, setLocation] = React.useState({})
  const [numberOfLocations, setNumberOfLocations] = React.useState(0)
  const [message, setMessage] = React.useState('')
  const [amountDue, setAmountDue] = React.useState(0)
  const [dateDue, setDateDue] = React.useState('-')
  const [daysRemaining, setDaysRemaining] = React.useState('-')
  const [invoices, setInvoices] = React.useState([])
  const [locations, setLocations] = React.useState(null)
  const [card, setCard] = React.useState(null)
  const [totalRequests, setTotalRequests] = React.useState(0)
  const [totalThumbsUp, setTotalThumbsUp] = React.useState(0)
  const [totalThumbsDown, setTotalThumbsDown] = React.useState(0)
  const [totalNegative, setTotalNegative] = React.useState(0)
  const [isLoading, setIsLoading] = React.useState(true)
  const [errors] = React.useState([])
  const { token } = React.useContext(AuthContext)
  const [startLoadingLocations, setStartLoadingLocations] = React.useState(false);

  const navigate = useNavigate();

  const getState = async () => {
    await getNumberOfLocations();
    setIsLoading(false);
  }

  React.useEffect(() => {
    if (startLoadingLocations) {
      getState()
    }
  }, [startLoadingLocations])

  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    }
  }

  const getFacebookReviews = async (bId) => {
    return await axios
      .get(`https://centralbarkbe.newbr.app/api/table/get-facebook-reviews/${bId}`, config)
      .catch(e => console.log(e.message))
      .then(el => {
        return el?.data?.data
      })
  }

  const getGoogleReviews = async (bId) => {
    return await axios
      .get(`https://centralbarkbe.newbr.app/api/table/get-google-reviews/${bId}`, config)
      .catch(e => console.log(e.message))
      .then(el => {
        return el?.data?.data
      })
  }

  const getNumberOfLocations = async () => {
    const locationsData = await axios
      .get('https://centralbarkbe.newbr.app/api/table/get-locations', config)
      .catch(reason => {
        if (reason.response.status === 401) {
          navigate("/login")
        }
      })

    setNumberOfLocations(locationsData.data.data.numberOfLocations)
    setLocations(locationsData.data.data.locations)
    const locationStats = locationsData.data.data.stats;

    let locationsCompleteArr = locationsData.data.data.locations?.map((location, index) => {
      if (location.bId === locationStats[index].bId) {
        return { ...location, ...locationStats[index] }
      }
    })

    setLocations(locationsCompleteArr)
    for (const location of locationsCompleteArr) {
      if (!location) continue
      if (location.requests) {
        setTotalRequests(prevState => typeof Number(location.requests) === 'number' ? prevState + Number(location.requests) : prevState)
      }
      setTotalThumbsUp(prevState => typeof Number(location.thumbsUp) === 'number' ? prevState + Number(location.thumbsUp) : prevState)
      setTotalThumbsDown(prevState => typeof Number(location.thumbsDown) === 'number' ? prevState + Number(location.thumbsDown) : prevState)
      setTotalNegative(prevState => typeof Number(location.negative) === 'number' ? prevState + Number(location.negative) : prevState)
      if (typeof location.googleReviews?.reviews != 'undefined' && location.googleReviews?.reviews) {
        location.googleReviews?.reviews.forEach(loc => {
          location.reviewsReceived = location.reviewsReceived + 1
          // }
        })
      }
      if (typeof location.facebookReviews?.reviews != 'undefined' && location.facebookReviews?.reviews) {
        location?.facebookReviews?.reviews?.forEach(loc => {
          location.reviewsReceived = location.reviewsReceived + 1
        })
      }
    }
    return true
  }

  const addNewLocation = async (locationData) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      }

      const businessIdExists = await axios.post('https://centralbarkbe.newbr.app/api/table/check-business-id-exists', JSON.stringify({ businessId: locationData.businessId }), config)
      if (businessIdExists.data.message === 'BusinessId already exists') {
        toast.error('BusinessId already exists, please choose a different one')
        return
      }

      if (!numberOfLocations) {
        locationData.quantity = 1
        const body = JSON.stringify(locationData)
        localStorage.setItem('location', JSON.stringify(locationData))
        const response = await axios.post('https://centralbarkbe.newbr.app/api/payment/create-checkout-session', body, config)
        const url = response.data.url
        window.location = url
      } else {
        locationData.quantity = numberOfLocations + 1
        const body = JSON.stringify(locationData)
        try {
          await axios.post('https://centralbarkbe.newbr.app/api/payment/update-subscription', body, config)
          insertTableEntries('', locationData)
        } catch (error) {
          console.log(error.message)
          toast.error('Something went wrong', error.message)
        }
      }
    } catch (error) {
      console.log(error.message)
      toast.error('Something went wrong', error.message)
    }
  }

  const insertTableEntries = async (id = '', tableData = null) => {
    try {

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      }

      if (id) {
        const body = {
          sessionId: id,
          tableData: localStorage.getItem('location')
        }
        await axios.post('https://centralbarkbe.newbr.app/api/table/insert-table-entries-create-subscription', body, config)
      } else {
        const body = {
          tableData
        }
        await axios.post('https://centralbarkbe.newbr.app/api/table/insert-table-entries-update-subscription', body, config)
      }

      setNumberOfLocations(prevState => setNumberOfLocations(prevState + 1))
      toast.success('Location Added Successfully')
    } catch (error) {
      console.log(error.message)
      toast.error('Something went wrong', error.message)
    }
  }

  const getCardNumber = async () => {
    const response = await axios.get('https://centralbarkbe.newbr.app/api/payment/get-card-number', config)
    let expYear = response.data.card.expYear.toString().slice(-2)
    const card = { ...response.data.card, expYear: expYear.slice(-2) }
    setCard(card)
  }

  const cancelSubscription = async bId => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      }
      const body1 = JSON.stringify({ quantity: numberOfLocations - 1 })
      const response1 = await axios.post('https://centralbarkbe.newbr.app/api/payment/update-subscription', body1, config)
      const body2 = JSON.stringify({ bId })
      const response2 = await axios.post('https://centralbarkbe.newbr.app/api/table/update-table-cancel-subscription', body2, config)
      const newLocations = locations.filter(location => Number(location.bId) !== Number(bId))
      setNumberOfLocations(numberOfLocations - 1)
      setLocations(newLocations)
    } catch (error) {
      console.log('Error ', error.message)
      toast.error('Something went wrong with removing the location')
    }
  }

  const value = {
    errors,
    location, setLocation,
    numberOfLocations, setNumberOfLocations,
    message, setMessage,
    amountDue, setAmountDue,
    dateDue, setDateDue,
    daysRemaining, setDaysRemaining,
    invoices, setInvoices,
    card, setCard,
    locations, setLocations,
    totalRequests, setTotalRequests,
    totalThumbsUp, setTotalThumbsUp,
    totalThumbsDown, setTotalThumbsDown,
    totalNegative, setTotalNegative,
    isLoading,
    addNewLocation,
    insertTableEntries,
    getNumberOfLocations,
    getCardNumber,
    cancelSubscription,
    getFacebookReviews,
    getGoogleReviews,
    setIsLoading,
    setStartLoadingLocations
  }

  return <LocationContext.Provider value={value}>{children}</LocationContext.Provider>
}