import React from 'react'

const DashboardPopupNegativeCommentSlide = ({ franchise, comment }) => {
  return (
    <div className="dashboard-popup-negative-comment-slide">
      <div className="dashboard-popup-negative-comment-name-date">
        <p className="dashboard-popup-negative-comment-name" style={{ fontSize: '16px' }}>{comment.name}</p>
        <p className="dashboard-popup-negative-comment-date" style={{ fontSize: '16px' }}>{new Date(comment.date).toLocaleDateString()}</p>
      </div>
      <div className="dashboard-popup-negative-comment-text" style={{ fontSize: '14px' }}>
        {comment.message}
      </div>
    </div>
  )
}

export default DashboardPopupNegativeCommentSlide