import Carousel from 'react-elastic-carousel'
import DashboardPopupNegativeCommentSlide from './DashboardPopupNegativeCommentSlide'

const DashboardPopupBottom = ({ franchise }) => {
  return (
    <div className="dashboard-popup-bottom">
      <div className="dashboard-popup-bottom-left">
        <p className='dashboard-popup-negative-desc'>
          Negative comments submitted with the thumbs down function
        </p>
        {franchise.comments?.length > 0 && (
          <p className="dashboard-popup-negative-comment-number">
            <span className="dashboard-popup-negative-comment-active" style={{ fontSize: '14px' }}>1</span> of {franchise.comments.length}
          </p>
        )}
      </div>
      <div className="dashboard-popup-bottom-right">
        {
          franchise.comments?.length > 0 ? (
          <Carousel className='carousel-negative-comments'>
            {franchise.comments.map((comment, index) => (
              <DashboardPopupNegativeCommentSlide key={index} comment={comment} franchise={franchise} />
            ))}
          </Carousel>
          ) : <p style={{ fontSize: '14px' }}><strong>No negative comments</strong></p>
        }
      </div>
    </div>
  )
}

export default DashboardPopupBottom


