import TopRightInfo from "./TopRightInfo"
import { ReactComponent as LogoMobile } from '../assets/icons/bright-reviews-logo-mobile.svg'
import logoMobile from '../assets/icons/bright-reviews-logo-mobile.svg'

const MobileTop = () => {
  return (
    <div className="mobile-top">
      <div className="mobile-top-logo">
        <img src={logoMobile} alt='BPA' className='mobile-logo' />
      </div>
      <TopRightInfo />
    </div>
  )
}

export default MobileTop