import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import { BrowserRouter } from 'react-router-dom'
import { LocationProvider } from './contexts/locationContext';
import { UserProvider } from './contexts/userContext';
import { AuthProvider } from './contexts/authContext';
import { CentralbarkProvider } from './contexts/centralbarkContext';
import 'tippy.js/dist/tippy.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <AuthProvider>
      <LocationProvider>
        <UserProvider>
          <CentralbarkProvider>
            <App />
          </CentralbarkProvider>
        </UserProvider>
      </LocationProvider>
    </AuthProvider>
  </BrowserRouter>
);

