import { useState, useEffect, useContext } from 'react'
import checkmarkIcon from '../assets/icons/check.svg'
import plusIcon from '../assets/icons/plus.svg'
import closeIcon from '../assets/icons/times-billing.svg'
import visaIcon from '../assets/icons/cc-visa.svg'
import mastercardIcon from '../assets/icons/cc-mastercard.svg'
import amexIcon from '../assets/icons/cc-amex.svg'
import discoverIcon from '../assets/icons/cc-discover.svg'
import axios from 'axios'
import { Link } from 'react-router-dom'
import { LocationContext } from '../contexts/locationContext'
import TopRightInfo from '../components/TopRightInfo'
import Modal from 'react-modal'
import FormField from '../components/FormField'
import { UserContext } from '../contexts/userContext'
import { AuthContext } from '../contexts/authContext'
import CancelSubscriptionModal from '../components/modals/CancelSubscriptionModal'
import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
import BillingHistory from '../components/biling/BillingHistory'

const Billing = () => {

  const { token } = useContext(AuthContext)

  const { addNewCard } = useContext(UserContext)

  const datePlaceholder = new Date('01-01-2022').toDateString().split(' ')

  const modalStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '50vw',
      minHeight: '50vh',
      position: 'relative'
    },
  };

  const [formData, setFormData] = useState({
    cardName: '',
    cardNumber: '',
    cardExpDate: '',
    cardCVC: '',
    cardBillingAddress: '',
    cardCity: '',
    cardState: '',
    cardZip: '',
    cardCountry: ''
  })

  const onChangeHandler = e => setFormData(prevState => ({ ...prevState, [e.target.name]: e.target.value }))

  const changeCardSubmitHandler = e => {
    e.preventDefault()
    addNewCard(formData)
    setFormData({
      cardName: '',
      cardNumber: '',
      cardExpDate: '',
      cardCVC: '',
      cardBillingAddress: '',
      cardCity: '',
      cardState: '',
      cardZip: '',
      cardCountry: ''
    })
    setIsOpen(false)
  }

  const [modalIsOpen, setIsOpen] = useState(false);
  const [cancelSubscriptionModalIsOpen, setCancelSubscriptionModalIsOpen] = useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() { }

  function closeModal() {
    setIsOpen(false);
  }

  function closeCancelSubscriptionModal() {
    setCancelSubscriptionModalIsOpen(false)
  }

  const { 
    numberOfLocations,
    getNumberOfLocations,
    amountDue, 
    setAmountDue, 
    dateDue, 
    setDateDue, 
    daysRemaining, 
    setDaysRemaining, 
    invoices, 
    setInvoices, 
    card, 
    getCardNumber, 
    locations, 
    cancelSubscription
  } = useContext(LocationContext)

  useEffect(() => {
    if (!numberOfLocations) {
      getNumberOfLocations()
    }
    if (!card) {
      getCardNumber()
    }
  }, [numberOfLocations])

  const user = localStorage.getItem('br_cb_user') ? JSON.parse(localStorage.getItem('br_cb_user')) : null

  useEffect(() => {
    if (user) {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      }
      const fetchUpcomingInvoice = async () => {
        const { data: { data: result } } = await axios.get('https://centralbarkbe.newbr.app/api/payment/get-upcoming-invoice', config)
        setAmountDue(result.total)
        const date = new Date(result.periodEnd * 1000).toDateString()
        setDateDue(date)
        const days = Math.floor((result.periodEnd * 1000 - Date.now()) / (1000 * 60 * 60 * 24))
        setDaysRemaining(days)
      }

      fetchUpcomingInvoice()
    }
  }, [numberOfLocations])

  useEffect(() => {
    if (user) {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      }
      const fetchInvoices = async () => {
        const { data: { data: result } } = await axios.get(`https://centralbarkbe.newbr.app/api/payment/get-invoices`, config)
        const invoiceData = result.map(invoice => {
          return {
            number: invoice.number,
            date: new Date(invoice.date * 1000).toLocaleDateString(),
            amount: invoice.amountPaid / 100,
            status: invoice.status === true ? 'Paid' : String(invoice.status),
            pdf: invoice.pdf
          }
        })
        setInvoices(invoiceData)
      }

      fetchInvoices()
    }
  }, [])

  const cancelSubscriptionHandler = async (bId) => {
    confirmAlert({
      title: 'Are you sure you want to cancel?',
      message: 'You will NOT be able to use this location anymore!',
      buttons: [
        {
          label: 'Yes',
          onClick: () => cancelSubscription(bId)
        },
        {
          label: 'No',
        }
      ]
    });
  }

  return (
    <div className='billing-wrapper'>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <h1 className='dashboard-heading'>Billing</h1>
        <TopRightInfo />
      </div>
      <div className="billing-container">
        <div className="billing-subscription-info">
          <h6 className='billing-subscription-info-heading'>Subscription Info</h6>
          <div className="billing-subscription-info-content">
            {numberOfLocations ? <div className="billing-subscription-info-left">
              <div className="license-payment-price">
                <div className="license-payment">
                  <div className="license">
                    <p className='license-title'>Standard License</p>
                    <p className='license-info'>{numberOfLocations} locations</p>
                  </div>
                  <div className="payment">
                    <p className='payment-days'>{daysRemaining} days remaining</p>
                    <p className='payment-due'>Next payment due: <span className="payment-date">{`${dateDue.split(' ')[0]}, ${dateDue.split(' ')[1]} ${dateDue.split(' ')[2]}, ${dateDue.split(' ')[3]}`}</span></p>
                    <p className="payment-due">Amount due: <span className="payment-date">${amountDue > 0 ? amountDue / 100 : 0}</span> </p>
                  </div>
                </div>
                <div className="price-container">
                  <p className='price'>{`$${25 * numberOfLocations}/month`}</p>
                </div>
              </div>
              <div className='checkmark'>
                <img src={checkmarkIcon} alt="" className='checkmark-icon' />
              </div>
            </div> : <></>}
            <div className="billing-subscription-info-right">
              <div className="add-location">
                <Link to='/add-new-franchise'><img src={plusIcon} alt="" className='plus-icon' /></Link>
                <div className="add-location-text">
                  <p className='add-location-desc'>{numberOfLocations ? 'Additional' : 'Add'} Location</p>
                  <p className='add-location-price'>$25/month</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="billing-payment-method">
          <div className="billing-subscription-info">
            <h6 className='billing-subscription-info-heading'>Payment Method</h6>
            {numberOfLocations
              ? <div className="billing-subscription-info-content">
                <div className="billing-subscription-info-left payment-info-left">
                  <div className="payment-info-text">
                    <h6 className='payment-card-title'>CREDIT CARD</h6>
                    <div className='payment-visa-number'>
                      <img src={card?.brand === 'discover' ? discoverIcon : card?.brand === 'mastercard' ? mastercardIcon : card?.brand === 'amex' ? amexIcon : visaIcon} className='visa-icon' alt="" />
                      <p>
                        **** **** **** {card?.last4Digits}
                      </p>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }} className='payment-card-exp-date-and-button'>
                      {
                        card && (
                          <p style={{ color: '#B0AFB4', fontSize: '16px', marginBottom: '10px' }}>Expires: {card?.expMonth.toString().length < 2 ? `0${card.expMonth}` : card.expMonth} / {card?.expYear}</p>

                        )
                      }
                      <button className="payment-button">
                        Default
                      </button>
                    </div>
                  </div>
                  <div className='checkmark'>
                    <img src={checkmarkIcon} alt="" className='checkmark-icon' />
                  </div>
                </div>
                <div className="billing-subscription-info-right">
                  <div className="add-location">
                    <span onClick={openModal}><img src={plusIcon} alt="" className='plus-icon' /></span>
                  </div>
                </div>
              </div>
              : <p className='add-location-desc'>No card on file</p>
            }
          </div>

          <div>
            <Modal
              isOpen={modalIsOpen}
              onAfterOpen={afterOpenModal}
              onRequestClose={closeModal}
              style={modalStyles}
              contentLabel="Change Credit Card"
            >
              <h3 style={{ color: "#662E89", textAlign: 'center', fontSize: '2rem', marginBottom: '2rem' }}>Change Card</h3>
              <span style={{ fontSize: '3rem', cursor: 'pointer', position: 'absolute', top: '0.5rem', right: '2rem' }} onClick={closeModal}>&times;</span>
              <form onSubmit={changeCardSubmitHandler} className='change-card-form'>
                <FormField label='Name on card' name='cardName' value={formData.cardName} onChange={onChangeHandler} />
                <FormField label='Card number' name='cardNumber' value={formData.cardNumber} onChange={onChangeHandler} />
                <FormField label='Expiration date' name='cardExpDate' placeholder='MM/YY' value={formData.cardExpDate} onChange={onChangeHandler} />
                <FormField label='CVC' name='cardCVC' value={formData.cardCVC} onChange={onChangeHandler} />
                <FormField label='Billing Address' name='cardBillingAddress' value={formData.cardBillingAddress} onChange={onChangeHandler} />
                <FormField label='City' name='cardCity' value={formData.cardCity} onChange={onChangeHandler} />
                <FormField label='State' name='cardState' value={formData.cardState} onChange={onChangeHandler} />
                <FormField label='Zip' name='cardZip' value={formData.cardZip} onChange={onChangeHandler} />
                <FormField label='Country' name='cardCountry' value={formData.cardCountry} onChange={onChangeHandler} />
                <input type="submit" className='change-card-form-button' value="Update Card" />
              </form>
            </Modal>
          </div>

          <CancelSubscriptionModal isOpen={cancelSubscriptionModalIsOpen} closeModal={closeCancelSubscriptionModal} />

        </div>
        <BillingHistory invoices={invoices} />
        <div className="billing-subscriptions">
          <div className="billing-subscriptions-heading">
            <h6>Subscriptions</h6>
          </div>
          <div className="subscriptions-table-wrapper">
            <table className='subscriptions-table'>
              <thead>
                <tr>
                  <th>LOCATION ID</th>
                  <th>LOCATION NAME</th>
                  <th>DATE ADDED</th>
                  <th>CANCEL SUBSCRIPTION</th>
                </tr>
              </thead>
              <tbody>
                {locations && locations.length > 0 && locations.map(location => (
                  <tr key={location.bId}>
                    <td style={{
                      textAlign: 'start'
                    }}>{location.businessId}</td>
                    <td style={{
                      textAlign: 'start'
                    }}>{location.businessName}</td>
                    <td>{location.dateAdded}</td>
                    <td><img src={closeIcon} onClick={() => cancelSubscriptionHandler(location.bId)} className='close-times-icon' alt="" /></td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Billing