import { useState, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import Logo from '../components/Logo'
import { UserContext } from '../contexts/userContext'

const ForgotPassword = () => {
  const [email, setEmail] = useState('')

  const { error, sendResetPasswordEmail } = useContext(UserContext)

  const navigate = useNavigate()

  const forgotPasswordHandler = async e => {
    e.preventDefault()
    sendResetPasswordEmail(email)
    navigate("/login")
  }

  return (
    <div className='login-wrapper'>
      <Logo to="/login" />
      {error && <h4 style={{ color: '#ff0000', fontSize: '2rem', textAlign: 'center', padding: '1rem 0' }}>{error}</h4>}
      <form onSubmit={forgotPasswordHandler} className='login-form'>
        <div className="login-form-group">
          <label htmlFor="email">Your Email Address</label>
          <input value={email} onChange={e => setEmail(e.target.value)} type="email" className="login-email" required />
        </div>
        <input type='submit' className='login-button forgot-password-button' value='Get password reset link' />
      </form>
    </div>
  )
}

export default ForgotPassword