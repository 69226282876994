import { useEffect, useContext } from 'react'
import { Routes, Route } from 'react-router-dom'
import Dashboard from './pages/Dashboard';
import Billing from './pages/Billing';
import Login from './pages/Login';
import AddNewFranchise from './pages/AddNewFranchise';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { UserContext } from './contexts/userContext';
import AccountSettings from './pages/AccountSettings';
import Modal from 'react-modal'
import ForgotPassword from './pages/ForgotPassword';
import { useNavigate, useLocation } from 'react-router-dom'
import ResetPassword from './pages/ResetPassword';
import Layout from './components/Layout';
import Settings from './components/modals/GingrApiKeyModal';
import LinkExpired from './pages/LinkExpired';
import CreatePassword from './pages/CreatePassword';
import Authmiddleware from './authMiddleware';

Modal.setAppElement('#root');

const App = () => {
  return (
    <div className='main-wrapper'>
      <Routes>
      <Route path="/" element={<Authmiddleware />}>
            <Route index path='/' element={
              <Layout><Dashboard /></Layout>
            } />
            <Route path='/billing' element={
              <Layout><Billing /></Layout>
            } />
            <Route path='/add-new-franchise' element={
              <Layout><AddNewFranchise /></Layout>
            } />
            <Route path='/account-settings' element={
              <Layout><AccountSettings /></Layout>
            } />
      </Route>

        <Route path='/login' element={<Login />} />
        <Route path='/forgot-password' element={<ForgotPassword />} />
        <Route path='/reset-password' element={<ResetPassword />} />
        <Route path='/create-password' element={<CreatePassword />} />
        <Route path="/link-expired" element={<LinkExpired />} />
      </Routes>
      <ToastContainer style={{ fontSize: '16px' }} />
    </div>
  )
}

export default App;
