import { useState, createContext, useContext } from "react"
import axios from 'axios'
import { toast } from "react-toastify"
import { LocationContext } from "./locationContext"
import { AuthContext } from "./authContext"
import { useNavigate } from "react-router-dom"

export const UserContext = createContext({})

export const UserProvider = ({ children }) => {

  const navigate = useNavigate()

  const { token, setToken } = useContext(AuthContext)

  const { setCard } = useContext(LocationContext)

  const [user, setUser] = useState(localStorage.getItem('br_cb_user') ? JSON.parse(localStorage.getItem('br_cb_user')) : null)
  const [error, setError] = useState('')
  const [message, setMessage] = useState('')


  const login = async (email, password) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      }
  
      const body = JSON.stringify({ email, password })
      
      const response = await axios.post('https://centralbarkbe.newbr.app/api/auth/login', body, config)

      setUser(response?.data?.data?.user)

      localStorage.setItem('br_cb_user', JSON.stringify(response?.data?.data?.user))
      localStorage.setItem('br_cb_token', response?.data?.data?.token)
      setToken(localStorage.getItem('br_cb_token'))
      navigate('/')
    } catch (error) {
      console.log('Error', error)
      error.response && error.response.data.message && setError(error.response.data.message)
      if(error.response && error.response.data.message) {
        setError(error.response.data.message)
        setTimeout(() => {
          setError('')
        }, 3000)
      } else {
        setError('Something went wrong')
      }
    }
  }

  const logout = () => {
    localStorage.removeItem('br_cb_user')
    localStorage.removeItem('br_cb_token')
    setToken(null)
    setUser(null)
    navigate('/login')
  }

  const updateAccountSettings = async (name, email, password) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      }

      const originalEmail = JSON.parse(localStorage.getItem('br_cb_user')).email

      const body = JSON.stringify({ originalEmail, name, email, password })
      await axios.put('https://centralbarkbe.newbr.app/api/table/update-account-settings', body, config)
      const response = await axios.get(`https://centralbarkbe.newbr.app/api/table/get-account-settings/${email}`, config)
      const newAccountSettings = response.data.data
      localStorage.setItem('user', JSON.stringify(newAccountSettings))
      setUser(newAccountSettings)
      toast.success('Account settings were successfully updated')
    } catch (error) {
      console.log('Error', error.message)
      toast.error('Something went wrong with updating the account settings')
    }
  }

  const addNewCard = async cardInfo => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      }
  
      const body = JSON.stringify(cardInfo)
  
      const response = await axios.post('https://centralbarkbe.newbr.app/api/payment/add-new-card', body, config)
      const paymentId = response.data.data.id

      toast.success('New card added successfully')

      const body2 = JSON.stringify({ card: paymentId })
      await axios.post('https://centralbarkbe.newbr.app/api/payment/change-default-card', body2, config)
      const expYear = String(response.data.data.card.exp_year)
      const newDefaultCard = {
        brand: response.data.data.card.brand,
        expMonth: response.data.data.card.exp_month,
        expYear: expYear.slice(-2),
        last4Digits: response.data.data.card.last4
      }
      setCard(newDefaultCard)
    } catch (error) {
      console.log('Error ', error.message)
      toast.error('Something went wrong with changing the default card')
    }
  }

  const sendResetPasswordEmail = async email => {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    }

    const body = JSON.stringify({ email })

    try {
      const res = await axios.post('https://centralbarkbe.newbr.app/api/auth/send-reset-password-email', body, config)
      toast.success('Check your email for the reset password link')
    } catch (error) {
      toast.error('Something went wrong: ', error.message)
    }
  }

  const checkToken = async ({token, email}) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    }
    const body = JSON.stringify({ email })

    try {
      return axios.post('https://centralbarkbe.newbr.app/api/auth/check-token', body, config)
    } catch (error) {
      toast.error('Something went wrong: ', error.message)
    }
  }

  const resetPassword = async (email, password, token) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    }
    const body = JSON.stringify({ email, password })

    try {
      await axios.post('https://centralbarkbe.newbr.app/api/auth/reset-password', body, config)
      toast.success('Password reset was successful')
      navigate('/login')
    } catch (error) {
      toast.error('Something went wrong: ', error.message)
    }

  }

  const value = {
    user, setUser,
    error, setError,
    message, setMessage,
    login,
    logout,
    updateAccountSettings,
    addNewCard,
    sendResetPasswordEmail,
    resetPassword,
    checkToken
  }

  return <UserContext.Provider value={value}>
    {children}
  </UserContext.Provider>
}
