import React from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom';
import Logo from '../components/Logo'
import { UserContext } from '../contexts/userContext';

export default function LinkExpired() {
    const [searchParams] = useSearchParams();
    const email = searchParams.get("email");
    const navigate = useNavigate();
    const { sendResetPasswordEmail } = React.useContext(UserContext)

    const sendLink = () => {
        sendResetPasswordEmail(email)
        navigate("/login")
    };
    
    return (
        <div className='login-wrapper'>
            <Logo to="/login" />
            <p style={{fontSize: '2rem'}}>Link has expired. Click the button bellow to receive another one by email.</p>
            <input onClick={sendLink} className='login-button' value='Send another link' />
        </div>
    )
}
