import { useState, useContext } from 'react'
import { UserContext } from '../contexts/userContext'
import { ReactComponent as DropdownArrow } from '../assets/icons/angle-down.svg'
import { Link } from 'react-router-dom'

const TopRightInfo = () => {
  const { user: { name }, logout } = useContext(UserContext)

  const [menuOpen, setMenuOpen] = useState(false)

  const logoutHandler = () => {
    logout()
  }

  const dropdownMenuHandler = () => {
    setMenuOpen(prevState => !prevState)
  }

  return (
    <div className='top-right-info' style={{ display: 'flex', alignItems: 'center', gap: '1rem', position: 'relative' }}>
      <p style={{ fontSize: '1.7rem' }}>Hello, {name}</p>
      <DropdownArrow onClick={dropdownMenuHandler} style={{ width: '1rem', cursor: 'pointer' }} />
      {menuOpen && <div style={{ width: '20rem', zIndex: '9999', position: 'absolute', right: 0, top: '3rem', backgroundColor: '#fff', padding: '2rem 3rem', border: '1px solid rgba(0,0,0,0.2)', borderRadius: '5px', display: 'flex', flexDirection: 'column', gap: '1rem' }}>
        <Link to='/account-settings' style={{ textDecoration: 'none', color: '#333', fontSize: '1.5rem', color: '#662E89', textAlign: 'center' }}>Account Settings</Link>
        <button onClick={logoutHandler} style={{ background: '#662E89', color: '#fff', padding: '0.75rem 1.5rem', borderRadius: '0.5rem', border: 'none', textAlign: 'center', fontSize: '1.5rem', fontFamily: 'inherit', cursor: 'pointer', transition: 'all 0.5s ease-in-out' }}>Logout</button>
      </div>}
    </div>
  )
}

export default TopRightInfo