import { useState, useContext } from 'react'
import FormField from "../components/FormField"
import { toast } from 'react-toastify'
import TopRightInfo from '../components/TopRightInfo'
import { UserContext } from '../contexts/userContext'

const AddNewFranchise = () => {

  const { updateAccountSettings } = useContext(UserContext)
  const [user, setUser] = useState(localStorage.getItem('br_cb_user') ? localStorage.getItem('br_cb_user') : null)

  const [name, setName] = useState(user ? user.name : '')
  const [email, setEmail] = useState(user ? user.email : '')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')



  const onSubmitHandler = async e => {
    e.preventDefault()
    if(password !== confirmPassword) {
      toast.error('Password and Confirm Password fields do not match')
    } else {
      updateAccountSettings(name, email, password)
    }
  }

  return (
    <div className="add-new-franchise-wrapper">
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <h1 className='dashboard-heading'>Account Settings</h1>
        <TopRightInfo />
      </div>
      <div className="add-new-franchise-container">
        <h6 className="add-new-franchise-title">Account Info</h6>
        <form onSubmit={onSubmitHandler}>
          <div className="form-fields-wrapper">
            <FormField label='Name' name='name' value={name} onChange={e => setName(e.target.value)} />
            <FormField label='Email' type='email' name='email' value={email} onChange={e => setEmail(e.target.value)} />
            <FormField required={false} label='Password' type='password' name='password' value={password} onChange={e => setPassword(e.target.value)} />
            <FormField required={false} label='Confirm Password' type='password' name='confirmPassword' value={confirmPassword} onChange={e => setConfirmPassword(e.target.value)} />
          </div>
          <input className='add-button' type="submit" value="Update Account Info" />
        </form>
      </div>
    </div>
  )
}

export default AddNewFranchise