import React from 'react'
import CBForm from '../CBForm'
import { CentralbarkContext } from '../../contexts/centralbarkContext'

export default function GingrApiKeyModal({ store }) {
  const { patchStore } = React.useContext(CentralbarkContext)

  const onSubmit = async (values) => {
    await patchStore({ ...store, key: values.storeKey })
  }

  console.log({store});
  return (
    <div className="billing-container">
      <div>
        <h6 className='billing-subscription-info-heading' style={{ textAlign: 'center' }}>Ginger API key</h6>
        <div className="billing-subscription-info-content" style={{ justifyContent: 'center' }}>
          <div className="settings">
            <div className='form-group'>
              <CBForm store={store}
                onSubmit={onSubmit} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
