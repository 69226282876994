import starFilled from '../assets/icons/star-full.svg'
import facebookLogo from '../assets/icons/facebook.svg'

const DashboardPopupReviewSlide = ({ review }) => {
  return (
    <div className="dashboard-popup-review-slide">
      <div className="dashboard-popup-review-slide-top" style={{ alignItems: 'center' }}>
        <h6 className='dashboard-popup-review-name' style={{ display: 'flex', alignItems: 'center', gap: '5px', fontSize: '16px' }}><img referrerPolicy="no-referrer" src={review?.photo ? review.photo : facebookLogo} alt="" width={30} height={30} />{review?.author}</h6>
        <p className='dashboard-popup-review-date' style={{ fontSize: '16px' }}>{new Date(review?.dateCreated).toLocaleDateString()}</p>
      </div>
      <div className="dashboard-popup-review-slide-middle">
        {review?.rating === 'ONE' && (
          <>
            <img src={starFilled} alt="" className='dashboard-popup-star' />
          </>
        )}
        {review?.rating === 'TWO' && (
          <>
            <img src={starFilled} alt="" className='dashboard-popup-star' />
            <img src={starFilled} alt="" className='dashboard-popup-star' />
          </>
        )}
        {review?.rating === 'THREE' && (
          <>
            <img src={starFilled} alt="" className='dashboard-popup-star' />
            <img src={starFilled} alt="" className='dashboard-popup-star' />
            <img src={starFilled} alt="" className='dashboard-popup-star' />
          </>
        )}
        {review?.rating === 'FOUR' && (
          <>
            <img src={starFilled} alt="" className='dashboard-popup-star' />
            <img src={starFilled} alt="" className='dashboard-popup-star' />
            <img src={starFilled} alt="" className='dashboard-popup-star' />
            <img src={starFilled} alt="" className='dashboard-popup-star' />
          </>
        )}
        {review?.rating === 'FIVE' && (
          <>
            <img src={starFilled} alt="" className='dashboard-popup-star' />
            <img src={starFilled} alt="" className='dashboard-popup-star' />
            <img src={starFilled} alt="" className='dashboard-popup-star' />
            <img src={starFilled} alt="" className='dashboard-popup-star' />
            <img src={starFilled} alt="" className='dashboard-popup-star' />
          </>
        )}
        {!review?.rating ? review?.type === 'positive' ? <span style={{ color: '#662E89', fontSize: '16px' }}><strong>Recommends</strong></span> : <span style={{ color: '#ff0000', fontSize: '16px' }}><strong>Doesn't recommend</strong></span> : ''}
      </div>
      <div className="dashboard-popup-review-slide-bottom">
        <p className='dashboard-popup-review-text' style={{ fontSize: '14px' }}>{review.comment}</p>
      </div>
    </div>
  )
}

export default DashboardPopupReviewSlide
